<template lang="pug">
.super-container
  .content.background
    .row.body-login.card
      .col-md-12.body-login-mobile.p-0
        div.lg-img.text-center.mb-2
          img.lg-img.mb-0(:src="logo" style="width: 30%;")
        div
          .card-content.lg-custom-card-content
            div.col-md-12
              .alert.alert-danger(v-if="errors")
                .txt-error {{errors}}
            .pl-2(style="margin-bottom: 24px;") Nhập lại email tài khoản của bạn để nhận lại mật khẩu mới
            div.form-group.row(style="margin-left: 0; margin-right: 0;")
              div.col-md-12.pl-2.pr-2
                .form-group.custom-form-group(style='position:relative;margin-top:0px; margin-bottom: 0px;')
                  input#username.form-control(class="form-custom" type="text" name="email" placeholder="mail@example.com" required style='padding-right: 40px !important; border-radius: 5px !important;' autofocus v-model="email" autocomplete="off")
            div.form-group.row.mb-2.text-center(style='')
              div.col-md-12(style='padding-left: 24px !important; padding-right: 24px !important')
                button.btn.btn-custom(@click='submitFormConfirm') Gửi email cho tôi
</template>
    
<script>
//import firebase from 'firebase'
//import configJson from '../../../config'
//import store from "@/store";
//import {onLogout} from "@/vue-apollo";
// import Cryptojs from 'crypto-js'
import ApiRepository from "@/core/ApiRepository";
export default {
    name: 'Login',
    data() {
    return {
        email: '',
        errors: null,
        countSubmit: 0,
        logo: '../../../assets/logo.png',
    }
    },
    apollo: {
    },
    async mounted() {
   // window.scrollTo(0, 0)
    /*var that =this;
    var token = (await this.$AuthenticationApp.getToken());
    if (!this.$commonFuction.isEmpty(token)){
        this.$AuthenticationApp.checkToken(token).then(async (res) => {
        if (res){
            //token còn hạn
            that.$router.push({path: that.$constants.URL_ERR.REDIRECT});
        }
        })
    }*/
    },
    methods: {
    validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      console.log("SHIBABABABABA", re.test(email));
      return re.test(email);
    },
    async submitFormConfirm() {
        if (this.$commonFuction.isEmpty(this.email)){
            this.errors = "Email không được để trống!";
            return false;
        }
        if (!this.validateEmail(this.email)){
            this.errors = "Email không đúng định dạng!";
            return false;
        }

      var that = this
      try {
        var dataRequest = {
          email:this.email
        }
        const headers = {
          //"Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
        };
         await ApiRepository.post('/_api/users/forgot-password-admin', dataRequest, {headers});
        alert("Yêu cầu của bạn đã được ghi nhận. Vui lòng kiểm tra email để biết thêm chi tiết.")
       // this.$toast.add({ severity: 'success', summary: 'Thành công', detail: 'Yêu cầu của bạn đã được ghi nhận. Vui lòng kiểm tra email để biết thêm chi tiết.', life: 3000 })
        setInterval(function(){
          //Gọi API refeshtoken
          that.$router.push({name: 'login'});
        },1000*6 );
       // window.location.href = '/auth/login'
      } catch (err) {
        var message = err.response.data.message;
        //console.log("messagemessage:",message)
        if (message=='EMAIL_NOT_EXIST_ERR'){
          //var list_err = err.response.data.data
          alert("Email không tồn tại")
        }
        else {
          that.$toast.add({
            severity: "error",
            summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
            detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
            life: 3000,
          });
        }
        console.log(err);
        return false;
      }

        //send email to user with a new password
        // this.$AuthenticationApp.forgotPassword(this.email).then((res) => {
        //     if (res){
        //         this.$router.push({name: 'login'});
        //     }
        // })
       // this.$router.push({name: 'login'});

    }
    }
}
</script>
    
<style lang="scss">
    :root {
      --color-lg-fb: #3b5998;
      --color-lg-gg: #da4837;
      --color-lg-yellow: #ffd311;
      --font-family-sans-serif: "Segoe UI", sans-serif !important;
    }
    .body-login { width: 584px; margin: 0 auto;
      font-family: "Segoe UI", sans-serif !important;
      .lg-img { background: transparent; margin-bottom: 10px;}
      .lg-body {
        margin-left: 0%;
        margin-top: 0;
        width: 584px;
        height: auto;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 5px;
        opacity: 1;
        padding-top: 31px;
        .lg-custom-card-content {
          padding-top: 0;
          padding-left: 10px;
          padding-right: 10px;
          .line-strike {
            width: 100%;
            text-align: center;
            border-bottom: 1px solid #b4b4b4;
            line-height: 0.1em;
            margin: 30px 0px;
            span {
              background: #fff;
              padding: 0 10px;
              color: #b4b4b4;
              font-family: "adobe-clean", sans-serif;
            }
          }
          .custom-btn-fb {
            background-color: var(--color-lg-fb);
            color: white;
            font-size: 15px;
            font-family: "adobe-clean", sans-serif;
            text-align: center;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            display: block;
            width: 190x;
            height: 50px;
            margin-left: 50px;
            padding-top: 15px;
            cursor: pointer;
          }
          .custom-btn-gg {
            background-color: var(--color-lg-gg);
            color: white;
            font-size: 15px;
            // font-weight: bold;
            font-family: "adobe-clean", sans-serif;
            text-align: center;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            display: block;
            width: 190x;
            height: 50px;
            margin-left: 50px;
            padding-top: 15px;
            cursor: pointer;
          }
          .lg-icon-password {
            margin-top: -30px;
            display: block;
            position: absolute;
            right: 10px;
            padding: auto 5px;
            &:hover {
              cursor: pointer;
            }
          }
          .lg-btn-save {
            background: var(--color-lg-yellow);
            color: black;
            text-transform: none;
            font-size: 14px;
            font-family: "adobe-clean", sans-serif;
            border: 1px solid #ffd311;
            border-radius: 5px;
            // box-shadow: 0px 0px 0px;
            margin-top: 0px !important;
            width: 100%;
          }
          .lg-reg-pull-right {
            float: right;
          }
          .lg-forgot-password {
            font-family: "adobe-clean", sans-serif;
            &:hover {
              cursor: pointer;
            }
          }
          .lg-reg-now {
            font-family: "adobe-clean", sans-serif;
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
    .form-custom{
      padding-right: 42.5px;
      border-radius: 0 !important;
      box-shadow: none;
      border-color: #d2d6de;
      display: block;
      width: 100%;
      height: 48px;
      padding: 6px 12px;
      font-size: 16px;
      line-height: 1.42857143;
      color: #555;
      background-color: #fff;
      background-image: none;
      border: 1px solid #ccc;
      font-family: inherit;
    }
    .lg-icon-password {
      margin-top: -30px;
      display: block;
      position: absolute;
      right: 10px;
      bottom: 10px;
      padding: auto 5px;
      &:hover {
        cursor: pointer;
      }
    }
    .lg-icon-account{
      margin-top: -30px;
      display: block;
      position: absolute;
      bottom: 10px;
      right: 10px;
    }
    .btn-custom{
      height: 40px !important;
      background: #C40380 !important;
      // border: 1px solid #116fbf !important;
      border: unset !important;
      // margin-top: 8px !important;
      cursor: pointer !important;
      color: white;
      text-transform: none;
      font-size: 16px;
      // font-family: "adobe-clean", sans-serif;
      border-radius: 3px;
      width: 100%;
    }
    .btn:hover {
      color: white;
      text-decoration: none;
    }
    .forgot-password{
      &:hover{
        cursor: pointer;
      }
      a{
        color: #C40380 !important;
      }
    }
    body {
  height: 100%;
}
.auth-layout{
  height: 100%;
  padding: 0;
}
.super-container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
@media (max-width: 600px){
  .body-login{
    width: 90%;
    height: auto;
  }
  .content.background{
    width: 100%;
  }
  img { width: 45% !important;}
}
</style>
    